































































































.set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
